<template>
  <div>
    <b-form-group
      label="Nombre"
      label-for="input-title"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-title"
        name="input-title"
        v-model="$v.rubric_preset.title.$model"
        :state="validateState('title')"
        aria-describedby="input-title-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 2 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <div
        v-b-tooltip.v-secondary="'Editar Descripción'"
        class="rubric-preset-form-description rich-text-content mb-2"
        v-html="$v.rubric_preset.description.$model"
        @click="
          $bvModal.show(
            `edit-rubric-preset-description-modal-${rubric_preset.id}`
          )
        "
      ></div>
      <b-modal
        :id="`edit-rubric-preset-description-modal-${rubric_preset.id}`"
        :title="`Editar Descripción`"
        size="lg"
        hide-footer
      >
        <NewRichTextEditor
          :Object="RubricPreset"
          :Text="rubric_preset.description"
          @save="patchRubricPreset"
          @close="
            $bvModal.hide(
              `edit-rubric-preset-description-modal-${rubric_preset.id}`
            )
          "
        ></NewRichTextEditor>
      </b-modal>
      <b-form-invalid-feedback id="input-description-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group
      :label="`${$getVisibleNames(
        'evaluations.observationachievement',
        false,
        'Niveles de Logro'
      )} por:`"
      label-for="show_percentage_sign"
      label-cols="0"
      label-cols-sm="4"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <b-form-select
        id="show_percentage_sign"
        v-model="$v.rubric_preset.show_percentage_sign.$model"
        :state="validateState('show_percentage_sign')"
        :options="showPercentageSigns"
        value-field="value"
        text-field="label"
        aria-describedby="show_percentage_sign-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="show_percentage_sign-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group
      :label="`Agregar Descriptor a ${$getVisibleNames(
        'evaluations.observationachievement',
        false,
        'Niveles de Logro'
      )}:`"
      label-for="allow_criteria_performance_level_input"
      label-cols="0"
      label-cols-sm="4"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <b-form-select
        id="allow_criteria_performance_level_input"
        v-model="$v.rubric_preset.allow_criteria_performance_level_input.$model"
        :state="validateState('allow_criteria_performance_level_input')"
        :options="showPerformanceLevelInput"
        value-field="value"
        text-field="label"
        aria-describedby="allow_criteria_performance_level_input-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback
        id="allow_criteria_performance_level_input-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>

    <template v-if="show_rubric_preset && !isNaN(this.rubric_preset.id)">
      <b-form-group
        label-for="allow_criteria_performance_level_input"
        label-cols="0"
        label-cols-sm="4"
        label-cols-md="3"
        class="m-0 p-0"
      >
        <template #label>
          <div class="d-flex">
            <div class="mr-1 mt-1">
              {{
                $getVisibleNames(
                  "evaluations.observationachievement",
                  false,
                  "Niveles de Logro"
                )
              }}
            </div>
            <div>
              <button-add
                size="sm"
                v-b-tooltip.v-secondary.noninteractive="
                  `Agregar ${$getVisibleNames(
                    'evaluations.observationachievement',
                    false,
                    'Niveles de Logro'
                  )}`
                "
                @click="
                  $bvModal.show(
                    `modal-create-rubric-preset-unit-form-${rubric_preset.id}`
                  )
                "
              ></button-add>
            </div>
          </div>
        </template>
        <div class="container-unit-preset mb-2">
          <div
            class="mr-2 mb-2"
            v-for="unit in rubricPresetUnits"
            :key="unit.id"
          >
            <div class="unit-preset">
              {{ unit.title }}
              <div class="ml-2">
                <b-badge
                  variant="none"
                  class="badge-unit-score"
                  v-b-tooltip.v-secondary.noninteractive="
                    `Puntuación del ${$getVisibleNames(
                      'evaluations.observationachievement',
                      false,
                      'Niveles de Logro'
                    )}`
                  "
                  >{{ unit.score }}</b-badge
                >
                <b-button
                  variant="none"
                  size="sm"
                  class="edit-unit ml-2 p-0"
                  v-b-tooltip.v-secondary.noninteractive="
                    `Editar ${$getVisibleNames(
                      'evaluations.observationachievement',
                      false,
                      'Nivel de Logro'
                    )}`
                  "
                  @click="
                    $bvModal.show(
                      `modal-edit-rubric-preset-unit-form-${unit.id}`
                    )
                  "
                >
                  <b-icon-pencil-square
                    class="pencil-icon"
                  ></b-icon-pencil-square>
                </b-button>
                <b-button
                  variant="none"
                  size="sm"
                  class="delete-unit ml-2 p-0"
                  v-b-tooltip.v-secondary.noninteractive="
                    `Eliminar ${$getVisibleNames(
                      'evaluations.observationachievement',
                      false,
                      'Nivel de Logro'
                    )}`
                  "
                  @click="askForDeleteRubricPresetUnit(unit.id)"
                  ><b-icon-trash class="pencil-icon"></b-icon-trash
                ></b-button>
                <b-modal
                  :id="`modal-edit-rubric-preset-unit-form-${unit.id}`"
                  :title="`Editar ${$getVisibleNames(
                    'evaluations.observationachievement',
                    false,
                    'Nivel de Logro'
                  )}`"
                  size="md"
                  hide-footer
                >
                  <RubricPresetUnitForm
                    :RubricPreset="rubric_preset"
                    :RubricPresetUnit="unit"
                    @updated="
                      $bvModal.hide(
                        `modal-edit-rubric-preset-unit-form-${unit.id}`
                      )
                    "
                  ></RubricPresetUnitForm>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
      </b-form-group>
    </template>
    <template v-else-if="show_rubric_preset && isNaN(this.rubric_preset.id)">
      <b-form-group
        label-for="allow_criteria_performance_level_input"
        label-cols="0"
        label-cols-sm="4"
        label-cols-md="3"
        class="m-0 p-0"
      >
        <template #label>
          <div class="d-flex">
            <div class="mr-1 mt-1">
              {{
                $getVisibleNames(
                  "evaluations.observationachievement",
                  false,
                  "Niveles de Logro"
                )
              }}
            </div>
            <div>
              <button-add
                size="sm"
                v-b-tooltip.v-secondary.noninteractive="
                  `Agregar ${$getVisibleNames(
                    'evaluations.observationachievement',
                    false,
                    'Niveles de Logro'
                  )}`
                "
                @click="
                  $bvModal.show(
                    `modal-local-create-rubric-preset-unit-form-${rubric_preset.id}`
                  )
                "
              ></button-add>
            </div>
          </div>
        </template>
        <div class="container-unit-preset mb-2">
          <div
            class="mr-2 mb-2"
            v-for="(unit, index) in local_rubric_preset_units"
            :key="index"
          >
            <div class="unit-preset">
              {{ unit.title }}
              <div class="ml-2">
                <b-badge
                  variant="none"
                  class="badge-unit-score"
                  v-b-tooltip.v-secondary.noninteractive="
                    `Puntuación del ${$getVisibleNames(
                      'evaluations.observationachievement',
                      false,
                      'Niveles de Logro'
                    )}`
                  "
                  >{{ unit.score }}</b-badge
                >
                <b-button
                  variant="none"
                  size="sm"
                  class="edit-unit ml-2 p-0"
                  v-b-tooltip.v-secondary.noninteractive="
                    `Editar ${$getVisibleNames(
                      'evaluations.observationachievement',
                      false,
                      'Nivel de Logro'
                    )}`
                  "
                  @click="
                    $bvModal.show(
                      `modal-local-edit-rubric-preset-unit-form-${index}`
                    )
                  "
                >
                  <b-icon-pencil-square
                    class="pencil-icon"
                  ></b-icon-pencil-square>
                </b-button>
                <b-button
                  variant="none"
                  size="sm"
                  class="delete-unit ml-2 p-0"
                  v-b-tooltip.v-secondary.noninteractive="
                    `Eliminar ${$getVisibleNames(
                      'evaluations.observationachievement',
                      false,
                      'Nivel de Logro'
                    )}`
                  "
                  @click="askForDeleteLocalRubricPresetUnit(index)"
                  ><b-icon-trash class="pencil-icon"></b-icon-trash
                ></b-button>
                <b-modal
                  :id="`modal-local-edit-rubric-preset-unit-form-${index}`"
                  :title="`Editar ${$getVisibleNames(
                    'evaluations.observationachievement',
                    false,
                    'Nivel de Logro'
                  )}`"
                  size="md"
                  hide-footer
                >
                  <RubricPresetUnitForm
                    :RubricPreset="rubric_preset"
                    :RubricPresetUnit="unit"
                    :permit_local_rubric_preset_unit="true"
                    @local_save="
                      (value) => localChangePresetUnitForm(value, index)
                    "
                  ></RubricPresetUnitForm>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
      </b-form-group>
    </template>
    <b-form-group
      v-if="!isNaN(this.rubric_preset.id)"
      label-for="input-modification-date"
      label-cols="0"
      label-cols-sm="0"
      class="p-0 m-0 mt-2"
    >
      <ModificationDate :Information="rubric_preset"></ModificationDate>
    </b-form-group>
    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(rubric_preset.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="deleteRubricPreset"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="m-0" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
    <b-modal
      :id="`modal-create-rubric-preset-unit-form-${rubric_preset.id}`"
      :title="`Crear ${$getVisibleNames(
        'evaluations.observationachievement',
        false,
        'Nivel de Logro'
      )}`"
      size="md"
      hide-footer
    >
      <RubricPresetUnitForm
        :RubricPreset="rubric_preset"
        @created="
          $bvModal.hide(
            `modal-create-rubric-preset-unit-form-${rubric_preset.id}`
          )
        "
      ></RubricPresetUnitForm>
    </b-modal>
    <b-modal
      :id="`modal-local-create-rubric-preset-unit-form-${rubric_preset.id}`"
      :title="`Crear ${$getVisibleNames(
        'evaluations.observationachievement',
        false,
        'Nivel de Logro'
      )}`"
      size="md"
      hide-footer
    >
      <RubricPresetUnitForm
        :RubricPreset="rubric_preset"
        :permit_local_rubric_preset_unit="true"
        @local_save="localPushPresetUnitForm"
      ></RubricPresetUnitForm>
    </b-modal>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
import * as names from "@/store/names";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";
import { mapGetters } from "vuex";
export default {
  name: "RubricPresetForm",
  mixins: [validationMixin],

  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    RubricPresetUnitForm: () =>
      import(
        "@/components/new-evaluations/Rubrics/RubricsPreset/RubricPresetUnitForm"
      ),
    ModificationDate: () => import("@/components/reusable/ModificationDate"),
  },
  props: {
    RubricPreset: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          title: "",
          description: "",
          order: 0,
          show_percentage_sign: true,
          allow_criteria_performance_level_input: true,
          updated_by: null,
          update_date: null,
        };
      },
    },
    show_rubric_preset: {
      type: Boolean,
      default: false,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rubric_preset: { ...this.RubricPreset },
      local_rubric_preset_units: [],
      showPercentageSigns: [
        {
          value: true,
          label: "Porcentaje",
        },
        {
          value: false,
          label: "Puntaje",
        },
      ],
      showPerformanceLevelInput: [
        {
          value: true,
          label: "Mostrar Descriptores",
        },
        {
          value: false,
          label: "Ocultar Descriptores",
        },
      ],
    };
  },
  validations: {
    rubric_preset: {
      title: {
        required,
        minLength: minLength(2),
      },
      description: {},
      show_percentage_sign: {},
      allow_criteria_performance_level_input: {},
    },
  },
  computed: {
    ...mapGetters({
      rubric_presets: names.RUBRIC_PRESETS,
      rubric_preset_units: names.RUBRIC_PRESET_UNITS,
    }),
    rubricPresetUnits() {
      return this.rubric_preset_units
        .filter((x) => x.rubric_preset == this.rubric_preset.id)
        .sort((a, b) => b.score - a.score);
    },
  },
  created() {},
  methods: {
    localPushPresetUnitForm(RubricPresetUnit) {
      this.local_rubric_preset_units.push(RubricPresetUnit);
      this.$bvModal.hide(
        `modal-local-create-rubric-preset-unit-form-${this.rubric_preset.id}`
      );
    },
    localChangePresetUnitForm(RubricPresetUnit, index) {
      this.local_rubric_preset_units.splice(index, 1, RubricPresetUnit);
      this.$bvModal.hide(`modal-local-edit-rubric-preset-unit-form-${index}`);
    },
    askForDeleteLocalRubricPresetUnit(index) {
      this.local_rubric_preset_units.splice(index, 1);
    },
    patchRubricPreset(object, text) {
      this.rubric_preset.description = text;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.rubric_preset[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.rubric_preset.$touch();
      if (this.$v.rubric_preset.$anyError) {
        return;
      }
      if (isNaN(this.rubric_preset.id)) {
        this.createRubricPreset();
      } else this.updateRubricPreset();
    },
    createRubricPreset() {
      this.rubric_preset.order = this.rubric_presets.length + 1;
      this.$store
        .dispatch(names.POST_RUBRIC_PRESET, this.rubric_preset)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "evaluations2.rubricpreset",
                false,
                "Modelo de Pauta"
              ) + " creada."
            )
          );
          if (this.show_rubric_preset)
            this.createLocalRubricPresetUnit(response);
          this.$emit("created", response);
        });
    },
    async createLocalRubricPresetUnit(rubric_preset) {
      let index = 0;
      for await (let rubric_preset_units of this.local_rubric_preset_units) {
        await this.$store.dispatch(names.POST_RUBRIC_PRESET_UNIT, {
          title: rubric_preset_units.title,
          description: rubric_preset_units.description,
          order: index + 1,
          score: rubric_preset_units.score,
          rubric_preset: rubric_preset.id,
        });
        index++;
      }
    },
    updateRubricPreset() {
      this.$store
        .dispatch(names.UPDATE_RUBRIC_PRESET, this.rubric_preset)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "evaluations2.rubricpreset",
                false,
                "Modelo de Pauta"
              ) + " actualizada."
            )
          );
          this.$emit("updated", response);
        });
    },
    deleteRubricPreset() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "evaluations2.rubricpreset",
          false,
          "Modelo de Pauta"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(
            names.DELETE_RUBRIC_PRESET,
            this.rubric_preset.id
          );
          this.$emit("deleted", this.rubric_preset);
        }
      });
    },
    askForDeleteRubricPresetUnit(rubric_preset_unit_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "evaluations.observationachievement",
          false,
          "Niveles de Logro"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_RUBRIC_PRESET_UNIT, rubric_preset_unit_id)
            .then(() => {
              toast(
                String(
                  this.$getVisibleNames(
                    "evaluations.observationachievement",
                    false,
                    "Niveles de Logro"
                  ) + " eliminado."
                )
              );
            });
        }
      });
    },
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.container-unit-preset {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.unit-preset {
  display: flex;
  padding: 3px 5px 3px 5px;
  background: var(--primary-color);
  border-radius: 3px;
}
.badge-unit-score {
  background: white;
  padding: 5px;
}
.rubric-preset-form-description {
  width: calc(100%);
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  height: auto;
  max-height: 200px;
  overflow: auto;
  padding: 0.25rem 1.2rem;
  line-height: 1.5;
}
.rubric-preset-form-description >>> p {
  margin-bottom: 0.7rem !important;
}
</style>