var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form-group',{staticClass:"label",attrs:{"label":"Nombre","label-for":"input-title","label-cols":"4","label-cols-sm":"3"}},[_c('b-form-input',{attrs:{"id":"input-title","name":"input-title","state":_vm.validateState('title'),"aria-describedby":"input-title-live-feedback","size":"sm"},model:{value:(_vm.$v.rubric_preset.title.$model),callback:function ($$v) {_vm.$set(_vm.$v.rubric_preset.title, "$model", $$v)},expression:"$v.rubric_preset.title.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-name-live-feedback"}},[_vm._v("Este campo debe tener al menos 2 caracteres.")])],1),_c('b-form-group',{staticClass:"label",attrs:{"label":"Descripción","label-for":"input-description","label-cols":"4","label-cols-sm":"3"}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:('Editar Descripción'),expression:"'Editar Descripción'",modifiers:{"v-secondary":true}}],staticClass:"rubric-preset-form-description rich-text-content mb-2",domProps:{"innerHTML":_vm._s(_vm.$v.rubric_preset.description.$model)},on:{"click":function($event){return _vm.$bvModal.show(
          `edit-rubric-preset-description-modal-${_vm.rubric_preset.id}`
        )}}}),_c('b-modal',{attrs:{"id":`edit-rubric-preset-description-modal-${_vm.rubric_preset.id}`,"title":`Editar Descripción`,"size":"lg","hide-footer":""}},[_c('NewRichTextEditor',{attrs:{"Object":_vm.RubricPreset,"Text":_vm.rubric_preset.description},on:{"save":_vm.patchRubricPreset,"close":function($event){return _vm.$bvModal.hide(
            `edit-rubric-preset-description-modal-${_vm.rubric_preset.id}`
          )}}})],1),_c('b-form-invalid-feedback',{attrs:{"id":"input-description-live-feedback"}},[_vm._v("Este campo debe tener al menos 5 caracteres.")])],1),_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label":`${_vm.$getVisibleNames(
      'evaluations.observationachievement',
      false,
      'Niveles de Logro'
    )} por:`,"label-for":"show_percentage_sign","label-cols":"0","label-cols-sm":"4","label-cols-md":"3"}},[_c('b-form-select',{attrs:{"id":"show_percentage_sign","state":_vm.validateState('show_percentage_sign'),"options":_vm.showPercentageSigns,"value-field":"value","text-field":"label","aria-describedby":"show_percentage_sign-feedback","size":"sm"},model:{value:(_vm.$v.rubric_preset.show_percentage_sign.$model),callback:function ($$v) {_vm.$set(_vm.$v.rubric_preset.show_percentage_sign, "$model", $$v)},expression:"$v.rubric_preset.show_percentage_sign.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"show_percentage_sign-feedback"}},[_vm._v("Este campo es obligatorio.")])],1),_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label":`Agregar Descriptor a ${_vm.$getVisibleNames(
      'evaluations.observationachievement',
      false,
      'Niveles de Logro'
    )}:`,"label-for":"allow_criteria_performance_level_input","label-cols":"0","label-cols-sm":"4","label-cols-md":"3"}},[_c('b-form-select',{attrs:{"id":"allow_criteria_performance_level_input","state":_vm.validateState('allow_criteria_performance_level_input'),"options":_vm.showPerformanceLevelInput,"value-field":"value","text-field":"label","aria-describedby":"allow_criteria_performance_level_input-feedback","size":"sm"},model:{value:(_vm.$v.rubric_preset.allow_criteria_performance_level_input.$model),callback:function ($$v) {_vm.$set(_vm.$v.rubric_preset.allow_criteria_performance_level_input, "$model", $$v)},expression:"$v.rubric_preset.allow_criteria_performance_level_input.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"allow_criteria_performance_level_input-feedback"}},[_vm._v("Este campo es obligatorio.")])],1),(_vm.show_rubric_preset && !isNaN(this.rubric_preset.id))?[_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label-for":"allow_criteria_performance_level_input","label-cols":"0","label-cols-sm":"4","label-cols-md":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-1 mt-1"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "evaluations.observationachievement", false, "Niveles de Logro" ))+" ")]),_c('div',[_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                `Agregar ${_vm.$getVisibleNames(
                  'evaluations.observationachievement',
                  false,
                  'Niveles de Logro'
                )}`
              ),expression:"\n                `Agregar ${$getVisibleNames(\n                  'evaluations.observationachievement',\n                  false,\n                  'Niveles de Logro'\n                )}`\n              ",modifiers:{"v-secondary":true,"noninteractive":true}}],attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
                  `modal-create-rubric-preset-unit-form-${_vm.rubric_preset.id}`
                )}}})],1)])]},proxy:true}],null,false,1222875642)},[_c('div',{staticClass:"container-unit-preset mb-2"},_vm._l((_vm.rubricPresetUnits),function(unit){return _c('div',{key:unit.id,staticClass:"mr-2 mb-2"},[_c('div',{staticClass:"unit-preset"},[_vm._v(" "+_vm._s(unit.title)+" "),_c('div',{staticClass:"ml-2"},[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                  `Puntuación del ${_vm.$getVisibleNames(
                    'evaluations.observationachievement',
                    false,
                    'Niveles de Logro'
                  )}`
                ),expression:"\n                  `Puntuación del ${$getVisibleNames(\n                    'evaluations.observationachievement',\n                    false,\n                    'Niveles de Logro'\n                  )}`\n                ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"badge-unit-score",attrs:{"variant":"none"}},[_vm._v(_vm._s(unit.score))]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                  `Editar ${_vm.$getVisibleNames(
                    'evaluations.observationachievement',
                    false,
                    'Nivel de Logro'
                  )}`
                ),expression:"\n                  `Editar ${$getVisibleNames(\n                    'evaluations.observationachievement',\n                    false,\n                    'Nivel de Logro'\n                  )}`\n                ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"edit-unit ml-2 p-0",attrs:{"variant":"none","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
                    `modal-edit-rubric-preset-unit-form-${unit.id}`
                  )}}},[_c('b-icon-pencil-square',{staticClass:"pencil-icon"})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                  `Eliminar ${_vm.$getVisibleNames(
                    'evaluations.observationachievement',
                    false,
                    'Nivel de Logro'
                  )}`
                ),expression:"\n                  `Eliminar ${$getVisibleNames(\n                    'evaluations.observationachievement',\n                    false,\n                    'Nivel de Logro'\n                  )}`\n                ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"delete-unit ml-2 p-0",attrs:{"variant":"none","size":"sm"},on:{"click":function($event){return _vm.askForDeleteRubricPresetUnit(unit.id)}}},[_c('b-icon-trash',{staticClass:"pencil-icon"})],1),_c('b-modal',{attrs:{"id":`modal-edit-rubric-preset-unit-form-${unit.id}`,"title":`Editar ${_vm.$getVisibleNames(
                  'evaluations.observationachievement',
                  false,
                  'Nivel de Logro'
                )}`,"size":"md","hide-footer":""}},[_c('RubricPresetUnitForm',{attrs:{"RubricPreset":_vm.rubric_preset,"RubricPresetUnit":unit},on:{"updated":function($event){return _vm.$bvModal.hide(
                      `modal-edit-rubric-preset-unit-form-${unit.id}`
                    )}}})],1)],1)])])}),0)])]:(_vm.show_rubric_preset && isNaN(this.rubric_preset.id))?[_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label-for":"allow_criteria_performance_level_input","label-cols":"0","label-cols-sm":"4","label-cols-md":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-1 mt-1"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "evaluations.observationachievement", false, "Niveles de Logro" ))+" ")]),_c('div',[_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                `Agregar ${_vm.$getVisibleNames(
                  'evaluations.observationachievement',
                  false,
                  'Niveles de Logro'
                )}`
              ),expression:"\n                `Agregar ${$getVisibleNames(\n                  'evaluations.observationachievement',\n                  false,\n                  'Niveles de Logro'\n                )}`\n              ",modifiers:{"v-secondary":true,"noninteractive":true}}],attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
                  `modal-local-create-rubric-preset-unit-form-${_vm.rubric_preset.id}`
                )}}})],1)])]},proxy:true}])},[_c('div',{staticClass:"container-unit-preset mb-2"},_vm._l((_vm.local_rubric_preset_units),function(unit,index){return _c('div',{key:index,staticClass:"mr-2 mb-2"},[_c('div',{staticClass:"unit-preset"},[_vm._v(" "+_vm._s(unit.title)+" "),_c('div',{staticClass:"ml-2"},[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                  `Puntuación del ${_vm.$getVisibleNames(
                    'evaluations.observationachievement',
                    false,
                    'Niveles de Logro'
                  )}`
                ),expression:"\n                  `Puntuación del ${$getVisibleNames(\n                    'evaluations.observationachievement',\n                    false,\n                    'Niveles de Logro'\n                  )}`\n                ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"badge-unit-score",attrs:{"variant":"none"}},[_vm._v(_vm._s(unit.score))]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                  `Editar ${_vm.$getVisibleNames(
                    'evaluations.observationachievement',
                    false,
                    'Nivel de Logro'
                  )}`
                ),expression:"\n                  `Editar ${$getVisibleNames(\n                    'evaluations.observationachievement',\n                    false,\n                    'Nivel de Logro'\n                  )}`\n                ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"edit-unit ml-2 p-0",attrs:{"variant":"none","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
                    `modal-local-edit-rubric-preset-unit-form-${index}`
                  )}}},[_c('b-icon-pencil-square',{staticClass:"pencil-icon"})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                  `Eliminar ${_vm.$getVisibleNames(
                    'evaluations.observationachievement',
                    false,
                    'Nivel de Logro'
                  )}`
                ),expression:"\n                  `Eliminar ${$getVisibleNames(\n                    'evaluations.observationachievement',\n                    false,\n                    'Nivel de Logro'\n                  )}`\n                ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"delete-unit ml-2 p-0",attrs:{"variant":"none","size":"sm"},on:{"click":function($event){return _vm.askForDeleteLocalRubricPresetUnit(index)}}},[_c('b-icon-trash',{staticClass:"pencil-icon"})],1),_c('b-modal',{attrs:{"id":`modal-local-edit-rubric-preset-unit-form-${index}`,"title":`Editar ${_vm.$getVisibleNames(
                  'evaluations.observationachievement',
                  false,
                  'Nivel de Logro'
                )}`,"size":"md","hide-footer":""}},[_c('RubricPresetUnitForm',{attrs:{"RubricPreset":_vm.rubric_preset,"RubricPresetUnit":unit,"permit_local_rubric_preset_unit":true},on:{"local_save":(value) => _vm.localChangePresetUnitForm(value, index)}})],1)],1)])])}),0)])]:_vm._e(),(!isNaN(this.rubric_preset.id))?_c('b-form-group',{staticClass:"p-0 m-0 mt-2",attrs:{"label-for":"input-modification-date","label-cols":"0","label-cols-sm":"0"}},[_c('ModificationDate',{attrs:{"Information":_vm.rubric_preset}})],1):_vm._e(),_c('div',{staticClass:"row"},[(_vm.show_delete_button && !isNaN(_vm.rubric_preset.id))?_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"danger"},on:{"click":_vm.deleteRubricPreset}},[_vm._v("Eliminar")])],1):_vm._e(),(_vm.show_save_button)?_c('div',{staticClass:"col",staticStyle:{"text-align":"right"}},[_c('b-button',{staticClass:"m-0",attrs:{"size":"sm"},on:{"click":_vm.save}},[_vm._v("Guardar")])],1):_vm._e()]),_c('b-modal',{attrs:{"id":`modal-create-rubric-preset-unit-form-${_vm.rubric_preset.id}`,"title":`Crear ${_vm.$getVisibleNames(
      'evaluations.observationachievement',
      false,
      'Nivel de Logro'
    )}`,"size":"md","hide-footer":""}},[_c('RubricPresetUnitForm',{attrs:{"RubricPreset":_vm.rubric_preset},on:{"created":function($event){return _vm.$bvModal.hide(
          `modal-create-rubric-preset-unit-form-${_vm.rubric_preset.id}`
        )}}})],1),_c('b-modal',{attrs:{"id":`modal-local-create-rubric-preset-unit-form-${_vm.rubric_preset.id}`,"title":`Crear ${_vm.$getVisibleNames(
      'evaluations.observationachievement',
      false,
      'Nivel de Logro'
    )}`,"size":"md","hide-footer":""}},[_c('RubricPresetUnitForm',{attrs:{"RubricPreset":_vm.rubric_preset,"permit_local_rubric_preset_unit":true},on:{"local_save":_vm.localPushPresetUnitForm}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }